
import { ISimpleDataItem } from '@/modules/system/types';
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class ModalList extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => false })
  readonly loading: boolean | undefined;

  @Prop({ default: () => false })
  readonly readonly: any;

  @Prop({ default: () => [] })
  readonly items: Array<ISimpleDataItem> | undefined;

  @Prop({ default: () => '' })
  readonly size: string | undefined;

  @Prop({ default: () => '' })
  readonly caption: string | undefined;

  // List view modes:
  // - name-only
  // - name-and-id
  // - name-and-code
  // - name-and-descr
  // - full
  @Prop({ default: () => '' })
  readonly mode: string | undefined;

  get isEditable() {
    return !this.readonly && this.readonly !== '';
  }

  get listMode(): string {
    return this.mode || 'name-and-id';
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  get width(): number {
    if (this.size === 'x-small') {
      return 250;
    } else if (this.size === 'small') {
      return 350;
    } else if (this.size === 'large') {
      return 600;
    } else if (this.size === 'x-large') {
      return 900;
    } else {
      return 450;
    }
  }

  append() {
    this.$emit('append')
  }

  select(item: ISimpleDataItem) {
    this.$emit('select', item)
  }

  remove(index: number) {
    this.$emit('remove', index)
  }
}
